import { FETCH_SUGGESTION, RESET_SUGGESTION } from "../../actions/suggestionAction";


const initialState = {};

export function suggestionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case `${FETCH_SUGGESTION}_SUCCESS`: {
            return {
                ...state,
                [payload['data']['status']]: payload.data.data,
            };
        }

        case RESET_SUGGESTION: {
            return {
                ...state,
                [payload['status']]: []
            };
        }


        default:
            return state;
    }
}
