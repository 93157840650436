import { memo } from "react";
import { prepareWidget } from '../util';

const WidgetWrapper = ({
    widget_conf,
    handleUpdateKeyValue,
    profile,
    processFileUpload,
    generateSuggestions,
    suggestion,
    errors }) => {
    return (
        <>
            {
                prepareWidget(
                    widget_conf,
                    handleUpdateKeyValue,
                    profile,
                    processFileUpload,
                    generateSuggestions,
                    suggestion,
                    errors)
            }
        </>
    );
}

export default memo(WidgetWrapper);



