
import { format, compareAsc } from 'date-fns'


function JobsWrapper({ jobs, history, sub_token }) {
    // console.log(job);

    return <div className="jobs-countainer">
        {
            jobs.map(job => {
                const job_date = format(new Date(job['created_at']), 'dd MMM yyyy');
                const deadline = format(new Date(job['deadline'] * 1000), 'dd MMM yyyy');
                return (
                    <div className="job row" key={job['id']}>


                        <div className="col-sm-2">
                            <h5 className="job_title">{job['title']}</h5>
                            <p className="company_name">{job['unit']}</p>
                        </div>
                        <div className="col-sm-3">
                            <p className="job_text">
                                Location: {job['job_data']['location']}
                            </p>
                            <p className="job_text">
                                Job Type: {job['job_data']['employment_status']}
                            </p>
                            <p className="job_text">
                                Experience: {job['job_data']['experience_requirements']}
                            </p>
                        </div>

                        <div className="col-sm-2">
                            <p className="job_text">
                                Applied Candidates :
                            </p>
                            <p className="job_candidate_num">
                                400+
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <p className="job_text">
                                Job Post Date: {job_date}
                            </p>
                            <p className="job_text">
                                Deadline: {deadline}
                            </p>
                        </div>

                        <div className="col-sm-1">
                            <a onClick={(e) => {
                                e.preventDefault();
                                history(`/job/shortlist/${sub_token}/${job['id']}`);
                            }} className="shortlist_button" href="#">Create Shortlist</a>
                            <a style={{ marginTop: '10px' }} onClick={(e) => {
                                e.preventDefault();
                                history(`/job/shortlist/${sub_token}/${job['id']}`);
                            }} className="shortlist_button" href="#">
                                Interview Schedule
                            </a>
                        </div>

                    </div>
                )
            })
        }
    </div>

}

export default JobsWrapper;
