const m_aarray = [
  {
    label: "Married",
    value: "Married",
  },
  {
    label: "Unmarried",
    value: "Unmarried",
  },
];

const oc_array1 = [
  {
    label: "Service",
    value: "Service",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Self Employed",
    value: "Self Employed",
  },
  {
    label: "Retired",
    value: "Retired",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const oc_array2 = [
  {
    label: "Service",
    value: "Service",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Self Employed",
    value: "Self Employed",
  },
  {
    label: "Retired",
    value: "Retired",
  },
  {
    label: "House Wife",
    value: "House Wife",
  },
  {
    label: "Other",
    value: "Other",
  },
];
export const widget_conf = {
  basic: [
    {
      title: "Personal information",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Full name",
              root_key: "basic",
              sub_key: "name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Fullname is required"],
              },
            },
            {
              label: 'Upload NID Copy (Image/PDF)',
              root_key: "basic",
              sub_key: "nid_image",
              type: "file",
              validation: {
                rule: ["required"],
                message: ["Upload ID Photo is required"],
              },
            },
            {
              label: "Blood Group",
              root_key: "basic",
              sub_key: "blood_group",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Blood Group is required"],
              },
            },
            {
              label: "NID",
              root_key: "basic",
              sub_key: "nid",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["NID is required"],
              },
            },
            {
              label: "Email",
              root_key: "basic",
              sub_key: "email",
              type: "email",
              validation: {
                rule: ["required", "email"],
                message: ["Email is required", "Invalid email"],
              },
            },
            {
              label: "Number of year experience	",
              root_key: "basic",
              sub_key: "num_year_experience",
              type: "select",
              data: [
                {
                  label: "0-1 Year",
                  value: "0-1 Year",
                },
                {
                  label: "1-2 Year",
                  value: "1-2 Year",
                },
                {
                  label: "2-3 Year",
                  value: "2-3 Year",
                },
                {
                  label: "3-4 Year",
                  value: "3-4 Year",
                },
                {
                  label: "5 year and above",
                  value: "5 year and above",
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This Gender is required"],
              }
            }
          ]
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "DOB",
              root_key: "basic",
              sub_key: "dob",
              type: "date",
              validation: {
                rule: ["required"],
                message: ["DOB is required"],
              },
            },
            {
              label: "Marital Status",
              root_key: "basic",
              sub_key: "marital_status",
              type: "select",
              data: m_aarray,
              validation: {
                rule: ["required"],
                message: ["Marital Status is required"],
              },
            },
            {
              label: "Pasport number",
              root_key: "basic",
              sub_key: "pasport_number",
              type: "text",
            },
            {
              label: "Phone",
              root_key: "basic",
              sub_key: "phone",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Phone is required"],
              },
            },
            {
              label: 'Upload resume',
              root_key: "basic",
              sub_key: "resume_copy",
              type: "file",

            },
            {
              label: "Gender	",
              root_key: "basic",
              sub_key: "gender",
              type: "select",
              data: [
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This Gender is required"],
              }
            }
          ],
        },
      ],
    },
  ],

  family: [
    {
      title: "Family information",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Father’s Name",
              root_key: "family",
              sub_key: "fathers_name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Father’s Name is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Occupation",
              root_key: "family",
              sub_key: "father_occupation",
              type: "select",
              data: oc_array1,
              validation: {
                rule: ["required"],
                message: ["Occupation is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label:
                "If in service / business, please specify Organization, Designation / nature of business & Address: ",
              root_key: "family",
              sub_key: "father_occupation_detail",
              type: "textarea",
            },
          ],
        },
      ],
    },

    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Mother’s Name",
              root_key: "family",
              sub_key: "mothers_name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Mother’s Name is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Occupation",
              root_key: "family",
              sub_key: "mothers_occupation",
              type: "select",
              data: oc_array2,
              validation: {
                rule: ["required"],
                message: ["Occupation is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label:
                "If in service / business, please specify Organization, Designation / nature of business & Address: ",
              root_key: "family",
              sub_key: "mothers_occupation_detail",
              type: "textarea",
            },
          ],
        },
      ],
    },

    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Spouse Name",
              root_key: "family",
              sub_key: "spouse_name",
              type: "text",
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Occupation",
              root_key: "family",
              sub_key: "spouse_occupation",
              type: "select",
              data: oc_array2,
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label:
                "If in service / business, please specify Organization, Designation / nature of business & Address: ",
              root_key: "family",
              sub_key: "spouse_occupation_detail",
              type: "textarea",
            },
          ],
        },
      ],
    },

    {
      title:
        "Number of Children (if any then please specify & mention the number)",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Son",
              root_key: "family",
              sub_key: "spouse_son_num",
              type: "text",
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Daughter",
              root_key: "family",
              sub_key: "spouse_daughter_num",
              type: "text",
            },
          ],
        },
      ],
    },
  ],
  address: [
    {
      title: "Permanent address",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Address line 1",
              root_key: "address",
              sub_key: "permanent_address_line1",
              type: "textarea",
              validation: {
                rule: ["required"],
                message: ["Address line is required"],
              },
            },
            {
              label: "Division",
              root_key: "address",
              sub_key: "permanent_division",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Division is required"],
              },
            },
            {
              label: "Thana/ Upazila",
              root_key: "address",
              sub_key: "permanent_thana",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Thana/ Upazila is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Address line 2",
              root_key: "address",
              sub_key: "permanent_address_line2",
              type: "textarea",
            },
            {
              label: "District",
              root_key: "address",
              sub_key: "permanent_district",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["District is required"],
              },
            },
            {
              label: "Postal code",
              root_key: "address",
              sub_key: "permanent_postal_code",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Postal code is required"],
              },
            },
          ],
        },
      ],
    },
    {
      title: "Present address",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Address line 1",
              root_key: "address",
              sub_key: "present_address_line1",
              type: "textarea",
            },
            {
              label: "Division",
              root_key: "address",
              sub_key: "present_division",
              type: "text",
            },
            {
              label: "Thana/ Upazila",
              root_key: "address",
              sub_key: "present_thana",
              type: "text",
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Address line 2",
              root_key: "address",
              sub_key: "present_address_line2",
              type: "textarea",
            },
            {
              label: "District",
              root_key: "address",
              sub_key: "present_district",
              type: "text",
            },
            {
              label: "Postal code",
              root_key: "address",
              sub_key: "present_postal_code",
              type: "text",
            },
          ],
        },
      ],
    },
  ],

  police_record: [
    {
      title: "Police Record",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label:
                "Have your ever been arrested by law enforcing agency or convicted in any court?",
              root_key: "police_record",
              sub_key: "police_record",
              type: "select",
              data: [
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Yes",
                  value: "Yes",
                },
              ],
              validation: {
                rule: ["required"],
                message: ["Police record is required"],
              },
            },
          ],
        },
        ,
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "if yes please specify",
              root_key: "police_record",
              sub_key: "police_record_detaail",
              type: "textarea",
            },
          ],
        },
      ],
    },
  ],
  recent_job: [
    {
      title: "Present/Recent Job Information",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Name of the Organization",
              root_key: "recent_job",
              sub_key: "organization_name",
              type: "text",
            },
            {
              label: "Department",
              root_key: "recent_job",
              sub_key: "department",
              type: "text",
            },
            {
              label: "Job Location",
              root_key: "recent_job",
              sub_key: "location",
              type: "text",
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Designation",
              root_key: "recent_job",
              sub_key: "designation",
              type: "text",
            },
            {
              label: "Business Unit",
              root_key: "recent_job",
              sub_key: "business_unit",
              type: "text",
            },
            {
              label: "Contact Number (s): PABX (with extension)",
              root_key: "recent_job",
              sub_key: "contact_number",
              type: "text",
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Cell",
              root_key: "recent_job",
              sub_key: "cell",
              type: "text",
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Email",
              root_key: "recent_job",
              sub_key: "email",
              type: "text",
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Date of joining",
              root_key: "recent_job",
              sub_key: "date_of_join",
              type: "date",
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label:
                "Name, Designation & Contact Number of reporting Supervisor:",
              root_key: "recent_job",
              sub_key: "supervisor_detail",
              type: "textarea",
            },
          ],
        },
      ],
    },
  ],
  recent_benefit: [
    {
      title: "Salary, Benefits & Facilities of Current/Last Position",
      wrappers: [
        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Present Gross Salary BDT:",
              root_key: "recent_benefit",
              sub_key: "gross_salary",
              type: "text",
            },
          ],
        },
        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Bonus Tk:",
              root_key: "recent_benefit",
              sub_key: "bonus",
              type: "text",
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Number of Bonus:",
              root_key: "recent_benefit",
              sub_key: "num_of_bonus",
              type: "text",
            },
          ],
        },

        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Pick & Drop facility provided by Company Transport: ",
              root_key: "recent_benefit",
              sub_key: "pick_and_drop",
              type: "select",
              data: [
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Yes",
                  value: "Yes",
                },
              ],
            },
          ],
        },

        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Fulltime Company Car:",
              root_key: "recent_benefit",
              sub_key: "full_time_car",
              type: "select",
              data: [
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Yes",
                  value: "Yes",
                },
              ],
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Other Benefits:",
              root_key: "recent_benefit",
              sub_key: "other_benefits",
              type: "textarea",
            },
          ],
        },
      ],
    },
  ],
  expected_benefit: [
    {
      title: "Salary, Benefits & Facilities of Current/Last Position",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Expected Salary BDT",
              root_key: "expected_benefit",
              sub_key: "salary",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Expected Salary is required"],
              },
              index: true
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Notice Period",
              root_key: "expected_benefit",
              sub_key: "notice",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Notice Period is required"],
              },
            },
          ],
        },
      ],
    },
  ],
  job_relation: [
    {
      title: "Job Relation with Bashundhara Group",
      wrappers: [
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Have you previously worked in Bashundhara Group",
              root_key: "job_relation",
              sub_key: "previous_work_with_group",
              type: "select",
              data: [
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Yes",
                  value: "Yes",
                },
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "PF",
              root_key: "job_relation",
              sub_key: "previous_pf",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Desigation",
              root_key: "job_relation",
              sub_key: "previous_designation",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Unit",
              root_key: "job_relation",
              sub_key: "previous_unit",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Duration: from",
              root_key: "job_relation",
              sub_key: "start_date",
              type: "date"
            },
          ]
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "To",
              root_key: "job_relation",
              sub_key: "end_date",
              type: "date"
            },
          ]
        },

        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Reason of leaving",
              root_key: "job_relation",
              sub_key: "previous_unit",
              type: "textarea"
            },
          ]
        }

      ],
    },

    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Do you have any kin/relative  working or previously worked in Bashundhara Group?",
              root_key: "job_relation",
              sub_key: "relative_previously_working",
              type: "select",
              data: [
                {
                  label: "No",
                  value: "No",
                },
                {
                  label: "Yes",
                  value: "Yes",
                },
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "PF",
              root_key: "job_relation",
              sub_key: "relative_pf",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Position",
              root_key: "job_relation",
              sub_key: "relative_position",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Unit",
              root_key: "job_relation",
              sub_key: "relative_unit",
              type: "text"
            },
          ]
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Relation",
              root_key: "job_relation",
              sub_key: "relative_unit",
              type: "text"
            },
          ]
        }

      ],
    }
  ],

  computer_literacy: [
    {
      title: "Computer Literacy",
      wrappers: [
        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "MS Word",
              root_key: "computer_literacy",
              sub_key: "ms_word",
              type: "select",
              data: [
                {
                  label: "Advance",
                  value: "Advance",
                },
                {
                  label: "Competent",
                  value: "Competent",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate"
                },
                {
                  label: "Basic",
                  value: "Basic"
                },
                {
                  label: "Rookie",
                  value: "Rookie"
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "MS Excel",
              root_key: "computer_literacy",
              sub_key: "ms_excel",
              type: "select",
              data: [
                {
                  label: "Advance",
                  value: "Advance",
                },
                {
                  label: "Competent",
                  value: "Competent",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate"
                },
                {
                  label: "Basic",
                  value: "Basic"
                },
                {
                  label: "Rookie",
                  value: "Rookie"
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "MS PowerPoint	",
              root_key: "computer_literacy",
              sub_key: "ms_powerPoint",
              type: "select",
              data: [
                {
                  label: "Advance",
                  value: "Advance",
                },
                {
                  label: "Competent",
                  value: "Competent",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate"
                },
                {
                  label: "Basic",
                  value: "Basic"
                },
                {
                  label: "Rookie",
                  value: "Rookie"
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },
          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Emailing	",
              root_key: "computer_literacy",
              sub_key: "emailing",
              type: "select",
              data: [
                {
                  label: "Advance",
                  value: "Advance",
                },
                {
                  label: "Competent",
                  value: "Competent",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate"
                },
                {
                  label: "Basic",
                  value: "Basic"
                },
                {
                  label: "Rookie",
                  value: "Rookie"
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            }

          ],
        },

        {
          wrapper: "col-sm-4",
          widgets: [
            {
              label: "Photoshop",
              root_key: "computer_literacy",
              sub_key: "photoshop",
              type: "select",
              data: [
                {
                  label: "Advance",
                  value: "Advance",
                },
                {
                  label: "Competent",
                  value: "Competent",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate"
                },
                {
                  label: "Basic",
                  value: "Basic"
                },
                {
                  label: "Rookie",
                  value: "Rookie"
                }
              ],
              validation: {
                rule: ["required"],
                message: ["This feild is required"],
              },
            },

          ],
        }






      ]
    }
  ],
  documents: [
    {
      title: "Upload Documents",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: 'Upload Resume',
              root_key: "documents",
              sub_key: "resume",
              type: "file",
              validation: {
                rule: ["required"],
                message: ["Resume is required"],
              },
            }
          ]
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: 'Other Document',
              root_key: "documents",
              sub_key: "other_document",
              type: "file",

            }
          ]
        }
      ]
    }
  ],
  declaration: [
    {
      hide_section: true,
      title: "  I hereby declare that the information furnished above are entirely true, accurate and authentic.",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              front_display: false,
              label: "Please select option",
              root_key: "declaration",
              sub_key: "declaration",
              type: "select",
              data: [
                {
                  label: 'Yes',
                  value: 'Yes'
                }
              ],
              validation: {
                rule: ["required"],
                message: ["Declaration  is required"],
              },
            }
          ]
        }
      ]
    },
  ]
};

export const modal_input_Config = {
  education_config: [
    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Education level",
              root_key: "educations",
              sub_key: "education_level",
              type: "autocomplete",
              ins_type: 5,
              index_name: 'education_level',
              validation: {
                rule: ["required"],
                message: ["Education level is required"],
              },
            },
            {
              label: "Subject",
              root_key: "educations",
              sub_key: "subject",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Subject is required"],
              },
            },
            {
              label: "Passing Year",
              root_key: "educations",
              sub_key: "year",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Passing Year is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Institute",
              root_key: "educations",
              sub_key: "institute",
              type: "autocomplete",
              ins_type: 3,
              index_name: 'institute',
              validation: {
                rule: ["required"],
                message: ["Institute is required"],
              }
            },
            {
              label: "GPA/ CGPA",
              root_key: "educations",
              sub_key: "result",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["GPA/ CGPA is required"],
              },
            },
            {
              label: 'Certificate copy pdf/image',
              root_key: "educations",
              sub_key: "certificate_copy",
              type: "file",
              validation: {
                rule: ["required"],
                message: ["Certificate copy pdf/image"],
              }
            }
          ],
        },
      ],
    },
  ],

  experience_config: [
    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Organisation name",
              root_key: "experiences",
              sub_key: "organisation_name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Organisation name is required"],
              },
            },
            {
              label: "Location",
              root_key: "experiences",
              sub_key: "location",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Location is required"],
              },
            },
            {
              label: "Start Date",
              root_key: "experiences",
              sub_key: "start_date",
              type: "date",
              validation: {
                rule: ["required"],
                message: ["Start Date is required"],
              },
            },
            {
              label: "Supervisor's name",
              root_key: "experiences",
              sub_key: "supervisor_name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Supervisor's name is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Designation",
              root_key: "experiences",
              sub_key: "designation",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Designation name is required"],
              },
            },
            {
              label: "Department",
              root_key: "experiences",
              sub_key: "department",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Department name is required"],
              },
            },
            {
              label: "End Date",
              root_key: "experiences",
              sub_key: "end_date",
              type: "date",
            },
            {
              label: "Supervisor's email",
              root_key: "experiences",
              sub_key: "supervisor_email",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Supervisor's email is required"],
              },
            },
          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Responsibilities",
              root_key: "experiences",
              sub_key: "responsibilities",
              type: "textarea_plain",
              validation: {
                rule: ["required"],
                message: ["Responsibilities is required"],
              },
            },
          ],
        },
      ],
    },
  ],
  references_config: [
    {
      title: "",
      wrappers: [
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Name",
              root_key: "references",
              sub_key: "name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Organisation name is required"],
              },
            },

            {
              label: "Designation",
              root_key: "references",
              sub_key: "designation",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["designation is required"],
              },
            },


          ],
        },
        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Occupation",
              root_key: "references",
              sub_key: "occupation",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Occupation name is required"],
              },
            },


            {
              label: "Organization",
              root_key: "references",
              sub_key: "organization",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Organization name is required"],
              },
            }
          ],
        },

        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Phone",
              root_key: "references",
              sub_key: "phone",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Phone is required"],
              }
            }



          ],
        },

        {
          wrapper: "col-sm-6",
          widgets: [
            {
              label: "Email",
              root_key: "references",
              sub_key: "email",
              type: "text",
              validation: {
                rule: ["required", "email"],
                message: ["Email is required"],
              },
            }



          ],
        },
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Address",
              root_key: "references",
              sub_key: "address",
              type: "textarea_plain",
              validation: {
                rule: ["required"],
                message: ["Address is required"],
              },
            },
          ],
        },
      ],
    },
  ]
};

export const signup_config_variable = {
  signup_config: [
    {
      wrappers: [
        {
          wrapper: "col-sm-12",
          widgets: [
            {
              label: "Name",
              root_key: "signup",
              sub_key: "name",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Name is required"],
              },
            },
            {
              label: "Email",
              root_key: "signup",
              sub_key: "email",
              type: "email",
              validation: {
                rule: ["required", "email"],
                message: ["Email is required", "Invalid Email Address"],
              },
            },

            {
              label: "Phone",
              root_key: "signup",
              sub_key: "phone",
              type: "text",
              validation: {
                rule: ["required"],
                message: ["Phone is required"],
              },
            },
            {
              label: "Password",
              root_key: "signup",
              sub_key: "password",
              type: "password",
            },
          ],
        },
      ],
    },
  ],
};

// label={`Blood Group *`} value={profile['blood_group'] && profile['blood_group']} root_key={`basic`} sub_key={`blood_group`}
