import ProfileSection from "./ProfileSection";
import IMAGE from "../../images/profile.png";
import { widget_conf } from "../../config/config";


function ProfileWrapper({ profile }) {
    const all_keys = Object.keys(widget_conf);
    const fact = profile?.jobprofile?.profile_data;
    const educations = (profile['jobprofile'] && profile['jobprofile']['profile_data'] && profile['jobprofile']['profile_data']['educations']) ? profile['jobprofile']['profile_data']['educations'] : []
  
    return (
        <>
            <div className="profile_portion">
                <img src={profile['thumb']} />
                <div className="profile_name_portion">
                    <p className="profile_name_text">{profile['name']}</p>
                    <p className="profile_text">Email: {profile['email']}</p>
                    <p className="profile_text">Phone: {profile['phone']}</p>
                </div>
            </div>
            {
                fact && <>
                    {
                        all_keys.map((k, index) => {
                            return <ProfileSection profile={profile['jobprofile']['profile_data']} sections={widget_conf[k]} key={index} />
                        })
                    }
                </>
            }
            {
                educations.length > 0 && <div style={{padding: '15px'}}>
                    <h3>
                        Educations
                    </h3>
                    {
                        educations.map((education, key) => {
                            // console.log(education);
                            return <div key={key} className="row">
                                <div className="col-sm-4">
                                    <h4 style={{fontSize: '15px'}}>
                                        {education['institute']}
                                    </h4>
                                    <p>
                                        {education['education_level']},  {education['subject']} 
                                    </p>

                                </div>
                                <div className="col-sm-4">
                                    <p>
                                       Year: {education['year']}
                                    </p>

                                </div>
                                <div className="col-sm-4">
                                    <p>
                                       Result: {education['result']}
                                    </p>

                                </div>
                            </div>
                        })
                    }
                </div>
            }

        </>
    );
}

export default ProfileWrapper;
