import { useState, useEffect } from 'react';
import $ from 'jquery';
import { parseProgressText } from '../../util';
export const ProfileCompletion = ({ current_index, params, completed }) => {

    useEffect(() => {
        if (current_index > 0)
            $(`#step-${current_index - 1}`).find('.progress-bar__bar').css('-webkit-transform', 'translateX(100%)');
    }, [current_index]);
    return (
        <div className="container-fluid">
            <ul className="list-unstyled multi-steps">

                {
                    params.map((sp, index) => {
                        return <li key={index} id={`step-${index}`} className={index === current_index ? `is-active` : ``}>
                            {parseProgressText(params[index])}
                            {
                                index < (params.length - 1) && <div className="progress-bar progress-bar--success">
                                    <div className="progress-bar__bar"> </div>
                                </div>

                            }

                        </li>
                    })
                }

            </ul>
        </div>
    );
};