function ProfileSection({ sections, profile }) {
    return sections.filter(s_section=>{
        return !s_section['hide_section'];
    }).map((section, s_key) => {
        return <section key={s_key} className="personal_detail">
            <h2>{section['title']}</h2>
            <div className="row">
                {
                    section['wrappers'].map((wrapper, wrapper_key) => {

                        return <div key={wrapper_key} className="col-sm-6">
                            {
                                wrapper['widgets'].map((widget, widget_key) => {
                                    const fact = profile?.[widget['root_key']]?.[widget['sub_key']];
                                    return (fact && widget['type'] === 'file') ? <p>
                                        {widget['label']}: <a href={profile[widget['root_key']][widget['sub_key']]} target="_blank">View</a>
                                    </p> :
                                        <p dangerouslySetInnerHTML={{ __html: `${widget['label']} : ${fact ? profile[widget['root_key']][widget['sub_key']] : ''}` }} ></p>


                                })
                            }
                        </div>
                    })
                }
            </div>
        </section>
    });

}

export default ProfileSection;
