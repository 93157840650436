
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateRootKeyValue,
    storeProfile,
    editRootKeyValueArray,
    updateRootKeyValueArray,
    dispatchUpload,
    resetProfile,
    fetchJobProfile,
    uploadJobFilesCustom
} from '../actions/profileAction';
import {
    UpdateUserProfileImage,
    fetchProfile,
    putToken
} from '../actions/';

import {
    applyJob,
    fetchJob
} from '../actions/jobAction';

import {
    fetchSuggestion, resetSuggestion
} from '../actions/suggestionAction';


import { NextPrev } from '../components/ActionButton/ButtonAll';
import { useNavigate } from "react-router-dom";
import { prepareErrorMessage, triggerPopUpClose } from '../util';
import { widget_conf } from '../config/config';
import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";
import ProfileImage from '../components/ImagesOpeeration/ProfileImage';
import { ProfileCompletion } from '../components/ProgressArea/ProfileCompletion';



import WidgetWrapper from '../components/WidgetWrapper';
import ArrayWrapper from '../components/ArrayWrapper';
function CommonScreen() {
    const { sub_token, job_id } = useParams();
    const params = ['basic', 'family', 'address', 'police_record', 'recent_job', 'recent_benefit', 'expected_benefit', 'job_relation', 'computer_literacy', 'educations', 'experiences', 'references', 'declaration'];
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const profileObject = useSelector(state => state.profile && state.profile);
    const [profile, setProfile] = useState({});
    const [complete, setComplete] = useState(false);
    const token = useSelector(state => state.auth['api_token'] && state.auth['api_token']);
    const [current_index, setCurrentIndex] = useState(0);
    const [thumb, setThumb] = useState(null);
    const [errors, setErrors] = useState({});
    const auth = useSelector(state => state.auth && state.auth);
    const suggestion = useSelector(state => state.suggestion && state.suggestion);

    const handleUpdateKeyValue = (root_key, key, value) => {
        setProfile({ ...profile, [key]: value });
        dispatch(updateRootKeyValue(root_key, key, value));
    }

    // console.log(suggestion);
    const generateSuggestions = async (title, index, status) => {
        // console.log(title, index, status);
        await dispatch(fetchSuggestion(title, index, status));
    }


    const updateEducation = (education) => {

        if (education['id']) {
            setProfile(profile.map(p => {
                return p['id'] === education['id'] ? {
                    ...p, ...education
                } : p
            }));
            dispatch(editRootKeyValueArray(params[current_index], education));

        }
        else {
            const id = (Math.random() + 1).toString(36).substring(7);
            setProfile(profile ? [...profile, education] : [profile]);
            dispatch(updateRootKeyValueArray(params[current_index], { ...education, id: id }));
        }


    }


    useEffect(() => {
        setProfile(profileObject[params[current_index]] ? profileObject[params[current_index]] : ((params[current_index] === 'educations' || params[current_index] === 'experiences' || params[current_index] === 'references') ? [] : {}));
    }, [current_index, profileObject]);


    useEffect(() => {
        (async () => {

            await dispatch(putToken(sub_token));
            if (job_id) {
                try {
                    await dispatch(fetchJob(job_id));
                } catch (e) { }
            }

            await dispatch(resetProfile());

            try {
                await dispatch(fetchProfile());
            } catch (e) { }


            try {
                await dispatch(fetchJobProfile());
            } catch (e) { }




        })();
    }, [sub_token]);

    const handleApplyJob = async (data) => {
        try {
            setLoading(true);
            await dispatch(applyJob(data));
            setLoading(false);
            triggerPopUpClose();
        } catch (e) { }
    }


    const processFileUpload = async (array, dispatch_trigger = true) => {
        const formData = new FormData();
        formData.append(`root_key`, array['root_key']);
        formData.append(`key_index`, array['key_index']);
        formData.append(`sub_key`, array['sub_key']);
        formData.append(`file`, array['file']);
        try {
            const response = await dispatch(uploadJobFilesCustom(formData));
            if (dispatch_trigger) {
                await dispatch(dispatchUpload(
                    response['payload']['data']['data']['root_key'],
                    response['payload']['data']['data']['sub_key'],
                    response['payload']['data']['data']['url']
                ));
            }
            return response['payload']['data']['data'];
        }
        catch (e) { }

    }



    const handleSavingData = async () => {
        if (current_index === (params.length - 1)) {
            await dispatch(storeProfile({ profile_data: profileObject, complete_profile: 1 }));
        } else {
            await dispatch(storeProfile({ profile_data: profileObject }));
        }
    }


    const handleNext = async () => {
        let custom_error = prepareErrorMessage(widget_conf[params[current_index]], profile);
        setErrors(custom_error);
        if (Object.keys(custom_error).length > 0)
            return;
        let next_index = 0;
        try {
            setLoading(true);
            if (thumb) {
                await dispatch(UpdateUserProfileImage({
                    image: thumb
                }));
                await dispatch(fetchProfile());
                setThumb(null);
            }

            await handleSavingData();
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        }
        if (current_index < (params.length - 1)) {
            setCurrentIndex((current_index) => {
                return current_index + 1;
            });
        } else {
            setComplete(true);
        }

    }

    const handlePrev = () => {
        let prev_index = 0;
        if (current_index > 0) {
            setComplete(false);
            setCurrentIndex((current_index) => {
                return current_index - 1;
            });
        }
    }

    // console.log(auth);

    return (
        <div style={{ marginTop: '100px' }}>
            <ProfileCompletion params={params} current_index={complete ? params.length : current_index} />
            <div className="container">
                <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '100px' }}>
                    <Form>
                        {
                            complete ? (
                                <div>
                                    <h1>
                                        You have completed your profile
                                    </h1>
                                </div>
                            ) : <>
                                {
                                    (params[current_index] === 'basic' && auth['profile']) && <ProfileImage auth={auth} setThumb={setThumb} />
                                }
                                {
                                    !(params[current_index] === 'educations' || params[current_index] === 'experiences' || params[current_index] === 'references') &&
                                    <WidgetWrapper
                                        processFileUpload={processFileUpload}
                                        widget_conf={widget_conf[params[current_index]]}
                                        handleUpdateKeyValue={handleUpdateKeyValue}
                                        profile={profile}
                                        errors={errors}
                                        generateSuggestions={generateSuggestions}
                                        suggestion={suggestion}
                                    />
                                }

                                {
                                    (params[current_index] === 'educations' && Array.isArray(profile)) &&
                                    <ArrayWrapper
                                        updateEducation={updateEducation}
                                        educations={profile}
                                        modal_key={`education_config`}
                                        title={`Academic History`}
                                        processFileUpload={processFileUpload}
                                        generateSuggestions={generateSuggestions}
                                        suggestion={suggestion}
                                    />
                                }


                                {
                                    (params[current_index] === 'experiences' && Array.isArray(profile)) &&
                                    <ArrayWrapper
                                        updateEducation={updateEducation}
                                        educations={profile}
                                        modal_key={`experience_config`}
                                        title={`Previous Career History`}
                                        processFileUpload={processFileUpload}
                                        generateSuggestions={generateSuggestions}
                                        suggestion={suggestion}
                                    />
                                }


                                {
                                    (params[current_index] === 'references' && Array.isArray(profile)) &&
                                    <ArrayWrapper
                                        updateEducation={updateEducation}
                                        educations={profile}
                                        modal_key={`references_config`}
                                        title={`References`}
                                        processFileUpload={processFileUpload}
                                        generateSuggestions={generateSuggestions}
                                        suggestion={suggestion}
                                    />
                                }
                            </>
                        }
                    </Form>
                </div>

                <NextPrev
                    handleApplyJob={handleApplyJob}
                    job_id={job_id}
                    complete={complete}
                    loading2={loading}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    enable_next={true}
                    enable_prev={current_index > 0 ? true : false
                    } />


            </div>
        </div>

    );
}

export default CommonScreen;
