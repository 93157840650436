import IMAGE from "../../images/profile.png";



function CandidateList({ candidates, status, retriveAndSetProfileIndex, makeApplyShortList, job }) {

    return <>
        {
            candidates.filter(
                (candidate) => {
                    if (status === 0)
                        return true;
                    else return candidate['pivot']['status'] === status;
                }
            ).map((candidate, index) => {
                return (
                    <div onClick={() => {
                        retriveAndSetProfileIndex(candidate['id']);
                    }} key={candidate['id']} className="condidate_list_single">
                        <div className="check_box_container">
                            <input onChange={() => {
                                makeApplyShortList(job['id'], candidate['id'], candidate['pivot']['status'] === 1 ? 0 : 1);
                            }} checked={candidate['pivot']['status'] === 1 ? true : false} className="checkmark" type={`checkbox`} />
                        </div>
                        <img src={candidate['thumb']} />
                        <p>{candidate['name']}</p>

                    </div>
                )
            })
        }


    </>
}

export default CandidateList;
