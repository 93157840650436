import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobRootKeyValue, storeJob, fetchCurretJobs, resetJob } from '../actions/jobAction';
import { SubmitButton } from '../components/ActionButton/ButtonAll';
import { useNavigate, useParams } from "react-router-dom";
import { prepareErrorMessage, prepareWidget } from '../util';
import { job_conf } from '../config/jobconfig';
import { UpdateUserProfileImage, fetchProfile, putToken } from '../actions/';

function JobCreate() {
    const { sub_token } = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const job = useSelector(state => state?.job?.job && state.job.job);
    const token = useSelector(state => state.auth['api_token'] && state.auth['api_token']);
    const auth = useSelector(state => state.auth && state.auth);

    const handleSave = async () => {
        let custom_error = prepareErrorMessage(job_conf['create_job'], job);
        setError(custom_error);
        if (Object.keys(custom_error).length === 0) {
            try {
                setLoading(true);
                await dispatch(storeJob({ job_data: job }));
                // await dispatch(fetchCurretJobs());
                await dispatch(resetJob());
                setLoading(false);
                // history("/");
            } catch (e) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        (async () => {
            await dispatch(putToken(sub_token));



            try {
                await dispatch(fetchProfile());
            } catch (e) { }







        })();
    }, [sub_token]);

    const handleUpdateKeyValue = (root_key, key, value) => {
        dispatch(updateJobRootKeyValue(root_key, key, value));
    }
    const processFileUpload = async (array, dispatch_trigger = true) => {


    }
    return (
        <div className="container">
            <div style={{ width: '70%', margin: 'auto' }}>
                <Form>
                    {
                        prepareWidget(job_conf['create_job'], handleUpdateKeyValue, job ? job : {}, processFileUpload, null, null, error)
                    }
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <SubmitButton handleSave={handleSave} loading={loading} />
                    </div>
                </Form>
            </div>


        </div>
    );
}

export default JobCreate;
