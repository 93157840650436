import { UPDATE_KEY_VALUE, UPDATE_KEY_VALUE_ARRAY, FETCH_JOB_PROFILE, EDIT_KEY_VALUE_ARRAY, UPLOAD_JOB_FILES, DISPATCH_UPLOAD, RESET_PROFILE } from '../../actions/profileAction';
import { LOGOUT } from '../../actions';
const initialState = {

};

export function profileReduceer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {



        case UPDATE_KEY_VALUE: {


            return {
                ...state, [payload['root_key']]: { ...state[payload['root_key']], [payload['key']]: payload['value'] }
            };

        }

        case UPDATE_KEY_VALUE_ARRAY: {

            return {
                ...state, [payload['root_key']]: state[payload['root_key']] ? [...state[payload['root_key']], payload['single_array']] : [payload['single_array']]
            };

        }


        case EDIT_KEY_VALUE_ARRAY: {

            return {
                ...state, [payload['root_key']]: state[payload['root_key']].map(single => {
                    return single['id'] === payload['single_array']['id'] ? { ...single, ...payload['single_array'] } : single
                })
            };

        }

        case `${FETCH_JOB_PROFILE}_SUCCESS`: {
            return {
                ...state, ...payload['data']['data']['profile_data']
            };
        }

        case DISPATCH_UPLOAD: {

            return {
                ...state, [payload['root_key']]: { ...state[payload['root_key']], [payload['sub_key']]: payload['url'] }
            };

        }
        case RESET_PROFILE:
            return initialState;
        case LOGOUT:
            return initialState;


        default:
            return state;
    }
}
