import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { fetchProfile, RegisterUser } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { prepareWidget } from "../util";
import { signup_config_variable } from "../config/config";

import logo from "../images/logo.png";

function NoMatch() {


    return (
        <div className="container">
            <div class="card shadow-lg o-hidden border-0 my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-lg-5 col-xxl-6 d-none d-lg-flex">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    margin: 60,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div class="col-lg-7 col-xxl-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h4 class="text-dark mb-4">404</h4>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoMatch;
