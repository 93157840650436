import React, { useState } from 'react';
import logo from './logo.svg';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import Button from 'react-bootstrap/Button';
import { NavBar } from './components/NavBar';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import HomeScreen from './Pages/HomeScreen';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, triggerLogout } from './actions';
import { fetchJobProfile } from './actions/profileAction';
import { fetchCurretJobs, resetJob } from './actions/jobAction';
import { useEffect } from 'react';



import CommonScreen from './Pages/CommonScreen';
import JobCreate from './Pages/JobCreate';


import NoMatch from './Pages/NoMatch';
import JobsScreen from './Pages/JobsScreen';
import ShortListScreen from './Pages/ShortListScreen';
import AllProfileScreen from './Pages/AllProfileScreen';
function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // console.log(urlParams);
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth['api_token'] && state.auth['api_token']);

  const logOutAction = () => {
    dispatch(triggerLogout());
  }
  const resetJobAction = () => {

    dispatch(resetJob());
  }

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Router>
        {/* {
          token && <NavBar auth={auth} token={token} triggerLogout={logOutAction} resetJobAction={resetJobAction} />
        } */}

        <Routes>


          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/profile/:slug/:sub_token" element={<CommonScreen />} />
          
          <Route exact path="/profile/:slug/:sub_token/apply/:job_id" element={<CommonScreen />} />
          <Route exact path="/job/create/:sub_token" element={<JobCreate />} />
          <Route exact path="/job/all/:sub_token" element={<JobsScreen />} />
          <Route exact path="/job/shortlist/:sub_token/:job_id" element={<ShortListScreen />} />
          <Route exact path="/admin/all/:sub_token" element={<AllProfileScreen />} />

          <Route path="*" element={<NoMatch />} />





        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default App;