import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function HomeScreen() {
  const history = useNavigate();
  const jobs = useSelector((state) =>
    state?.jobs?.data ? state.jobs.data : []
  );
  console.log(jobs);
  return (
    <div className="container mt-5 pt-5">
      {jobs.map((job) => {
        return (
          <a
            key={job["id"]}
            href=""
            className="card  mb-3 job-list"
            onClick={(e) => {
              e.preventDefault();
              history(`/job/detail/${job["id"]}`);
            }}
          >
            <div className="card-body">
              <h4 className="text-success">
                {job?.job_data?.designation && job["job_data"]["designation"]}
              </h4>
              <strong>ABG</strong>
              <ul className="mt-3">
                <li className="fas fa-map-marker">
                  {job?.job_data?.job?.location && job["job_data"]["location"]}
                </li>
                <li className="fas fa-graduation-cap">
                  {job?.job_data?.education && job["job_data"]["education"]}
                </li>
                <li className="fas fa-briefcase">
                  {job?.job_data?.location &&
                    job["job_data"]["experience_requirements"]}
                  <span className="float-end text-danger">
                    <i className="fas fa-calendar-alt"></i>
                    Deadline:
                    {job?.job_data?.deadline && job["job_data"]["deadline"]}
                  </span>
                </li>
              </ul>
            </div>
          </a>
        );
      })}
    </div>
  );
}

export default HomeScreen;


