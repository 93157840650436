import { fetchProfile, RegisterUser } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCurretJobs } from "../actions/jobAction";
import JobsWrapper from "../components/Jobs/JobsWrapper";


function JobsScreen() {
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const history = useNavigate()
    const { slug, sub_token, job_id } = useParams();
    const jobObject = useSelector(state => state.jobs && state.jobs);
    const jobs = jobObject['data'] ? jobObject['data'] : [];
    useEffect(() => {
        (async () => {

            try {
                await dispatch(fetchCurretJobs());
            } catch (e) { }


        })();
    }, []);

    return (
        <div className="container">
            <div className="tab_area">
                <ul className="tab_container">
                    <li className={`${index === 0 ? 'active' : ''}`}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setIndex(0);
                        }}>
                            All Jobs
                        </a>
                    </li>
                    <li className={`${index === 1 ? 'active' : ''}`}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setIndex(1);
                        }}>
                            Shortlisted Job
                        </a>
                    </li>
                    <li className={`${index === 2 ? 'active' : ''}`}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setIndex(2);
                        }}>
                            Interview
                        </a>
                    </li>
                </ul>
            </div>
            <JobsWrapper sub_token={sub_token} history={history} jobs={jobs} />
        </div>
    );
}

export default JobsScreen;
