import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { triggerPopUpClose } from '../../util';
export const NextPrev = (
    {
        complete = false,
        loading1 = false,
        loading2 = false,
        enable_prev = false,
        enable_next = false,
        enable_skip = false,
        handleNext = null,
        handlePrev = null,
        job_id = null,
        handleApplyJob
    }
) => {



    return (
        <div style={{ position: 'fixed', right: 0, left: 0, bottom: 0, height: "80px", xIndex: 99, backgroundColor: '#282828' }}>
            <div className='row' style={{ marginLeft: 'auto', marginRight: 'auto', width: '30%', marginTop: '20px' }}>

                <div className='col-sm-6'>
                    <button disabled={!enable_prev} className="w-100 btn btn-lg btn-danger" type="button" onClick={() => {
                        handlePrev();
                    }}>
                        {
                            loading1 ? <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : 'Previous'
                        }
                    </button>
                </div>


                {
                    (!complete && enable_next) && <div className='col-sm-6'>
                        <button onClick={() => {
                            handleNext();
                        }} className="w-100 btn btn-lg btn-danger" type="button">
                            {
                                loading2 ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : 'Save'
                            }
                        </button>
                    </div>
                }


                {
                    (complete && job_id) && <div className='col-sm-6'>
                        <button onClick={() => {
                            handleApplyJob({ job_id });
                        }} className="w-100 btn btn-lg btn-danger" type="button">
                            {
                                loading2 ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : 'Apply'
                            }
                        </button>
                    </div>
                }

                {
                    (complete && !job_id) && <div className='col-sm-6'>
                        <button onClick={() => {
                            triggerPopUpClose();
                        }} className="w-100 btn btn-lg btn-danger" type="button">
                            {
                                loading2 ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : 'Close'
                            }
                        </button>
                    </div>
                }

            </div>
        </div>


    );
};


export const SubmitButton = ({ loading = false, handleSave }) => {
    return (

        <div className='row'>



            <div className='col-sm-3'>
                <button onClick={() => {
                    handleSave();
                }} className="w-100 btn  btn-danger" type="button">
                    {
                        loading ? <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> : 'Save'
                    }
                </button>
            </div>




        </div>

    );
}