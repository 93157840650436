import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import {resetSuggestion} from "../../actions/suggestionAction";
const AutocompleteInput = (
  {
    generateSuggestions,
    suggestions = [],
    value,
    label,
    handleUpdateKeyValue,
    root_key,
    sub_key,
    invalid_message = '',
    invalid = false,
    index_name,
    ins_type
  }) => {

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const inputValue = event.target.value;
    handleUpdateKeyValue(root_key, sub_key, inputValue);
    if (inputValue === '') {
      handleReset(ins_type);
      return;
    }
    generateSuggestions(inputValue, index_name, ins_type);
  };

  const handleSelect = (selectedValue) => {
    handleUpdateKeyValue(root_key, sub_key, selectedValue);
    handleReset(ins_type);
  };


  const handleReset = (status) => {
    dispatch(resetSuggestion(status));
  }

  return (
    <div className="mb-3 position_relative">
      <Form.Label>{label}</Form.Label>
      <input placeholder={label} className="form-control" type="text" value={value} onChange={handleChange} />
      {
        invalid && <div className="invalid-feedback">
          {invalid_message}
        </div>
      }
      <ul className="suggestion">
        {suggestions.map((suggestion) => (
          <li key={suggestion} onClick={() => handleSelect(suggestion['title'])}>
            {suggestion['title']}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AutocompleteInput;