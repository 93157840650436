



// Design / Creative(119)
// Production / Operation(142)
// Hospitality / Travel / Tourism(117)
// Commercial(69)
// Beauty Care / Health & Fitness(15)
// IT & Telecommunication(466)
// Marketing / Sales(791)
// Customer Service / Call Centre(143)
// Media / Ad./ Event Mgt. (78)
// Medical / Pharma(193)
// Agro(Plant / Animal / Fisheries)(59)
// NGO / Development(186)
// Research / Consultancy(36)
// Secretary / Receptionist(87)
// Data Entry / Operator / BPO(71)
// Driving / Motor Technician(7)
// Security / Support Service(48)
// Law / Legal(25)
// Electrician / Construction / Repair(12)



const job_category = [
    {
        label: 'Accounting/Finance',
        value: 'Accounting/Finance'
    },
    {
        label: 'Bank/ Non-Bank Fin. Institution',
        value: 'Bank/ Non-Bank Fin. Institution'
    },
    {
        label: 'Supply Chain / Procurement',
        value: 'Supply Chain / Procurement'
    },
    {
        label: 'Education / Training',
        value: 'Education / Training'
    },
    {
        label: 'Engineer / Architects',
        value: 'Engineer / Architects'
    },
    {
        label: 'Garments / Textile',
        value: 'Garments / Textile'
    },
    {
        label: 'Supply Chain / Procurement',
        value: 'Supply Chain / Procurement'
    },

    {
        label: 'HR / Org.Development',
        value: 'HR / Org.Development'
    },

    {
        label: 'Gen Mgt / Admin',
        value: 'Gen Mgt / Admin'
    },

    {
        label: 'Design / Creative',
        value: 'Design / Creative'
    },

    {
        label: 'Production / Operation',
        value: 'Production / Operation'
    },

    {
        label: 'Hospitality / Travel / Tourism',
        value: 'Hospitality / Travel / Tourism'
    },
    {
        label: 'IT & Telecommunication',
        value: 'IT & Telecommunication'
    }


];



export const job_conf = {
    create_job: [
        {
            'title': 'Make Job Circular',
            'wrappers': [
                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Select Job Category',
                            root_key: 'job',
                            sub_key: 'category',
                            type: 'select',
                            data: job_category,
                            validation: {
                                rule: ['required'],
                                message: ['Please Select job Category']
                            }
                        },
                        {
                            label: 'Unit',
                            root_key: 'job',
                            sub_key: 'unit',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Please Unit']
                            }
                        },
                        {
                            label: 'Job Context',
                            root_key: 'job',
                            sub_key: 'job_context',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Job Context is required']
                            }
                        }

                    ]
                },
                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Designation',
                            root_key: 'job',
                            sub_key: 'designation',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Designation is required']
                            }
                        },
                        {
                            label: 'Employment Status',
                            root_key: 'job',
                            sub_key: 'employment_status',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Employment Status is required']
                            }
                        }


                    ]
                },
                {
                    wrapper: 'col-sm-12',
                    widgets: [
                        {
                            label: 'Education',
                            root_key: 'job',
                            sub_key: 'education',
                            type: 'textarea',
                            validation: {
                                rule: ['required'],
                                message: ['Education is required']
                            }
                        }



                    ]
                },
                {
                    wrapper: 'col-sm-12',
                    widgets: [
                        {
                            label: 'Job Responsibilities',
                            root_key: 'job',
                            sub_key: 'job_responsibilities',
                            type: 'textarea',
                            validation: {
                                rule: ['required'],
                                message: ['Job Responsibilities is required']
                            }
                        }



                    ]
                },


                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: "Experience Requirements",
                            root_key: 'job',
                            sub_key: 'experience_requirements',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Experience is required']
                            }
                        }


                    ]
                },

                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Job Location',
                            root_key: 'job',
                            sub_key: 'location',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Job Location is required']
                            }
                        }


                    ]
                },

                {
                    wrapper: 'col-sm-12',
                    widgets: [
                        {
                            label: 'Additional Requirements',
                            root_key: 'job',
                            sub_key: 'additional_requirements',
                            type: 'textarea',
                            validation: {
                                rule: ['required'],
                                message: ['Additional Requirements is required']
                            }
                        }



                    ]
                },
                {
                    wrapper: 'col-sm-12',
                    widgets: [
                        {
                            label: 'Compensation & Other Benefits',
                            root_key: 'job',
                            sub_key: 'compensation_other_benefits',
                            type: 'textarea',
                            validation: {
                                rule: ['required'],
                                message: ['Compensation & Other Benefits are required']
                            }
                        }



                    ]
                },

                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: "Salary",
                            root_key: 'job',
                            sub_key: 'salary',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Salary are required']
                            }
                        }


                    ]
                },

                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Deadline',
                            root_key: 'job',
                            sub_key: 'deadline',
                            type: 'date',
                            validation: {
                                rule: ['required'],
                                message: ['Deadline is required']
                            }
                        }


                    ]
                },

            ]
        }
    ],
    iterview_schedule: [
        {
            'title': 'Make Interview Schedule',
            'wrappers': [
                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Schedule',
                            root_key: 'job',
                            sub_key: 'interview_schedule',
                            type: 'datetime-local',
                            validation: {
                                rule: ['required'],
                                message: ['Schedule is required']
                            }
                        }


                    ]
                },

                {
                    wrapper: 'col-sm-12',
                    widgets: [
                        {
                            label: 'Message',
                            root_key: 'job',
                            sub_key: 'interview_message',
                            type: 'textarea',
                            validation: {
                                rule: ['required'],
                                message: ['Message is required']
                            }
                        }



                    ]
                }

            ]
        }
    ]








};



