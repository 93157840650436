
import Form from 'react-bootstrap/Form';
import { DebounceInput } from 'react-debounce-input';
function SearchFilter({ setTitle, setPage }) {

    return (
        <div className="mb-3" style={{ paddingTop: '25px' }}>
            <Form.Group className="mb-3">
                <DebounceInput
                    className="form-control"
                    placeholder="Search here..."
                    minLength={1}
                    debounceTimeout={500}
                    onChange={event => {
                        setTitle(event.target.value);
                        setPage(1);
                    }}
                />
            </Form.Group>
        </div>
    );
}

export default SearchFilter;
