import { fetchJob, makeShortList, fetchIndexes, fetchIndexesProfile } from "../actions/jobAction";
import { saveInterview } from "../actions/interviewAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilterArea from "../components/FilterPanel/FilterArea";

import ProfileSection from "../components/Profile/ProfileSection";
import CandidateList from "../components/Condidate/CandidateList";
import ProfileWrapper from "../components/Profile/ProfileWrapper";
import {

    putToken
} from '../actions/';
import { isInArrayFunction } from "../Utils/util";
import CallInterView from "../components/InterViewPanel/CallInterView";

function ShortListScreen() {
    const [modalShow, setModalShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [profile_index, setProfileIndex] = useState(0);
    const { sub_token, job_id } = useParams();
    const jobObject = useSelector(state => state.jobObject[job_id] && state.jobObject[job_id]);
    const filterObject = useSelector(state => state.filterObject && state.filterObject);
    const dispatch = useDispatch();
    const candidates = jobObject && jobObject['candidates'] ? jobObject['candidates'] : [];
    const [sort, setSort] = useState(0);
    const [filter_applied, setFilterApplied] = useState(false);
    const arrayFilter = filterObject && filterObject['candidate_filter'] ? filterObject['candidate_filter'] : []
    const [filter, setFilter] = useState(
        {
            filter1: '',
            filter2: '',
            filter3: '',
        });


    const [type, setType] = useState(
        {
            type1: '',
            type2: '',
            type3: '',
        });




    const selected_candidate = candidates.filter(candidate => {
        return candidate['pivot']['status'] === 1
    });
    const retriveAndSetProfileIndex = (id) => {
        let index = candidates.findIndex(can => {
            return can['id'] === id;
        });
        setProfileIndex(index);

    }
    const makeApplyShortList = async (job_id, candidate_id, status) => {
        await dispatch(makeShortList(job_id, candidate_id, status));
    }

    const filterFunction = (x) => {

        if (filter_applied) {
            return isInArrayFunction(x['id'], arrayFilter);
        }
        else
            return true;
    }

    const sortFunction = (x, y) => {

        if (sort === 0)
            return x['id'] - y['id'];
        else if (sort === 1) return y['id'] - x['id'];
        else if (sort === 3) return x.name.localeCompare(y.name);
        else return y.name.localeCompare(x.name);
    }


    const searchFilter = async (type, title, job_id) => {
        await dispatch(fetchIndexesProfile(type, title, job_id));
    }



    const handleSaveInterview = async (data) => {
        await dispatch(saveInterview(data));
    }





    useEffect(() => {
        (async () => {

            try {
                await dispatch(putToken(sub_token));
                await dispatch(fetchJob(job_id));
                await dispatch(fetchIndexes(1));
                await dispatch(fetchIndexes(2));
                await dispatch(fetchIndexes(3));

            } catch (e) { }


        })();
    }, []);

    return (
        <div className="container">
            {
                jobObject &&
                <>
                    <div className="job_heading row">
                        <h3>
                            {jobObject['title']}
                        </h3>
                    </div>
                    <FilterArea setFilterApplied={setFilterApplied} job_id={jobObject['id']} searchFilter={searchFilter} sort={sort} setSort={setSort} filterObject={filterObject} />
                    <div className="row condidate_container">
                        <div className="col-sm-3">
                            {/* <ul className="candidate_select">
                                <li className={`${index === 0 ? 'active' : ''}`}>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        setIndex(0);
                                    }} href="#">All Candidate</a>
                                </li>
                                <li className={`${index === 1 ? 'active' : ''}`}>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        setIndex(1);
                                    }} href="#">Shortlisted</a>
                                </li>

                            </ul> */}
                            <div style={{ paddingTop: '25px' }}>
                                <select onChange={async (e) => {
                                    setIndex(parseInt(e.target.value));
                                }} className="filter_drop_down">
                                    <option value={0}>All Candidates</option>
                                    <option value={1}>ShortListed</option>
                                </select>
                            </div>
                            <div className="condidate_list">
                                <CandidateList
                                    job={jobObject}
                                    makeApplyShortList={makeApplyShortList}
                                    retriveAndSetProfileIndex={retriveAndSetProfileIndex}
                                    status={index}
                                    candidates={jobObject['candidates'].sort(sortFunction).filter(filterFunction)}
                                />
                            </div>


                        </div>
                        <div className="col-sm-9 profile_area">
                            {
                                candidates[profile_index] && <ProfileWrapper profile={candidates[profile_index]} />
                            }
                        </div>
                    </div>
                    {
                        candidates[profile_index] && <div className="select_action">
                            <div className="container validate_action">
                                {
                                    selected_candidate.length > 0 ? <p>Selected Candidates ({selected_candidate.length})</p> : <p></p>
                                }
                                <div className="select_button" style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        makeApplyShortList(jobObject['id'], candidates[profile_index]['id'], candidates[profile_index]['pivot']['status'] === 1 ? 0 : 1)

                                    }} href="#">

                                        {
                                            candidates[profile_index]['pivot']['status'] === 0 ? ` Move to ShortList` : `Remove from Shortlist`
                                        }
                                    </a>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setModalShow(true);
                                    }} href="#">
                                        Call for interview
                                    </a>
                                    <CallInterView
                                        handleSaveInterview={handleSaveInterview}
                                        show={modalShow}
                                        onHide={setModalShow}
                                        job_id={jobObject['id']}
                                        user_id={candidates[profile_index]['id']}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                </>
            }

        </div >
    );
}

export default ShortListScreen;
