import { FETCH_FILTER_INDEX, FETCH_FILTER_PROFILE_INDEX } from '../../actions/jobAction';

const initialState = {

};

export function filterReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {



        case `${FETCH_FILTER_INDEX}_SUCCESS`: {


            return {
                ...state, [payload['data']['type']]: payload['data']['data']
            };

        }


        case `${FETCH_FILTER_PROFILE_INDEX}_SUCCESS`: {
            return {
                ...state, candidate_filter: payload['data']['data']
            };
        }






        default:
            return state;
    }
}
