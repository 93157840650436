export const FETCH_WALLET = 'FETCH_WALLET';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGOUT = 'LOGOUT';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_APPEND = 'FETCH_POST_APPEND';
export const MAKE_POST = 'MAKE_POST';
export const MAKE_IMAGE = 'MAKE_IMAGE';
export const INITIATE_QUEUE = 'INITIATE_QUEUE';
export const REGISTER_QUEUE = 'REGISTER_QUEUE';
export const UPLOAD_START = 'UPLOAD_START';
export const REQUEUE = 'REQUEUE';
export const REMOVE_QUEUE = 'REMOVE_QUEUE';
export const HEARTBEAT = 'HEARTBEAT';
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const STORE_CURRENCIES = 'STORE_CURRENCIES';
export const FETCH_USERS = 'FETCH_USERS';
export const MAKE_USER = 'MAKE_USER';
export const UPDATE_KEY_CHANGE = 'UPDATE_KEY_CHANGE';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_ALL_USER = 'FETCH_ALL_USER';
export const PUT_TOKEN = 'PUT_TOKEN';
export const FETCH_ALL_PROFILE = 'FETCH_ALL_PROFILE';


export const loginSubmit = (username, password) => {
    return {
        type: LOGIN_SUBMIT,
        payload: {
            request: {
                url: 'api/v1/login',
                method: 'post',
                data: {
                    username,
                    password,
                },
            },
        },
    };
};

export const triggerLogout = () => {
    return {
        type: LOGOUT,
    };
};

export const fetchProfile = () => {
    return {
        type: FETCH_PROFILE,
        payload: {
            request: {
                url: `api/v1/user`,
                method: 'get',
            },
        },
    };
};


export const fetchPosts = (page = 1) => {
    return {
        type: FETCH_POST,
        payload: {
            request: {
                url: `api/v1/posts?page=${page}`,
                method: 'get',
            },
        },
    };
};






export const makePost = (post) => {

    return {
        type: MAKE_POST,
        payload: {
            request: {
                url: 'api/v1/posts',
                method: 'post',
                data: post,
            },
        },
    };
};


export const makeImage = (data) => {
    // console.log(data);

    return {
        type: MAKE_IMAGE,
        payload: {
            request: {
                url: 'api/v1/posts/images',
                method: 'post',
                data: data,
            },
        },
    };
};


export const InitiateToQueue = (data) => {

    return {
        type: INITIATE_QUEUE,
        payload: {
            data: data
        },
    };
};


export const pushToQueue = (data) => {

    return {
        type: REGISTER_QUEUE,
        payload: {
            data: data
        },
    };
};


export const processToQueue = (data) => {
    console.log("Inpro");
    console.log(data);

    return {
        type: UPLOAD_START,
        payload: {
            data: data
        },
    };
};


export const RemoveToQueue = (data) => {

    return {
        type: REMOVE_QUEUE,
        payload: {
            data: data
        },
    };
};


export const ReQueue = (data) => {

    return {
        type: REQUEUE,
        payload: {
            data: data
        },
    };
};




export const createPost = async (dispatch, getState, post) => {
    await dispatch(
        makePost(post),
    );
}



export const createImage = async (dispatch, getState, image) => {
    try {

        const response = await dispatch(
            makeImage(image),
        );

    } catch (e) {

    }
}


export const fetchCurrencies = () => {
    return {
        type: FETCH_CURRENCIES,
        payload: {
            request: {
                url: `api/v1/currencies`,
                method: 'get',
            },
        },
    };
};


export const saveCurrency = (data) => {
    // console.log(data);
    return {
        type: STORE_CURRENCIES,
        payload: {
            request: {
                url: 'api/v1/currencies',
                method: 'post',
                data: data,
            },
        },
    };
};



export const fetchUsers = (page = 1, search = '') => {
    return {
        type: FETCH_USERS,
        payload: {
            request: {
                url: search === '' ? `api/v1/users?page=${page}` : `api/v1/users?search=${search}&page=${page}`,
                method: 'get',
            },
        },
    };
};



export const MakeUser = (data) => {
    return {
        type: MAKE_USER,
        payload: {
            request: {
                url: `api/v1/users`,
                method: 'post',
                data: data
            }
        }
    };
};


export const UpdateUserProfile = (data) => {
    return {
        type: UPDATE_USER,
        payload: {
            request: {
                url: `api/v1/users`,
                method: 'put',
                data: data
            }
        }
    };
};



export const UpdateKeyValue = (key, value, id) => {
    return {
        type: UPDATE_KEY_CHANGE,
        payload: {
            id, key, value
        }
    };
};


export const UpdateUserProfileImage = (data) => {
    return {
        type: UPDATE_USER,
        payload: {
            request: {
                url: `api/v1/users/thumb`,
                method: 'post',
                data: data
            }
        }
    };
};



export const fetchAllUser = () => {
    return {
        type: FETCH_ALL_USER,
        payload: {
            request: {
                url: `api/v1/users/all`,
                method: 'get',
            },
        },
    };
};

export const RegisterUser = (data) => {
    return {
        type: LOGIN_SUBMIT,
        payload: {
            request: {
                url: `api/v1/users`,
                method: 'post',
                data: data
            }
        }
    };
};





export const putToken = (token) => {

    return {
        type: PUT_TOKEN,
        payload: {
            token
        },
    };
};


export const uploadFile = (post) => {

    return {
        type: MAKE_POST,
        payload: {
            request: {
                url: 'api/v1/posts',
                method: 'post',
                data: post,
            },
        },
    };
};




export const fetchAllProfile = (page = 1, title = '', job_id = '', institute = '', salary = '', gender = '', experience = '') => {
    return {
        type: FETCH_USERS,
        payload: {
            request: {
                url: `api/v1/profile/all?page=${page}&title=${encodeURI(title)}&job_id=${job_id}&salary=${salary}&gender=${gender}&institute=${institute}&experience=${encodeURI(experience)}`,
                method: 'get',
            },
        },
    };
};