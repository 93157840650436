import Form from 'react-bootstrap/Form';
import { prepareWidget } from '../../util';
import { modal_input_Config } from '../../config/config';

function CommonModal({ updateKeyValue, modal_key = `education_config`, data, errors = {}, processFileUpload, suggestion, generateSuggestions }) {

    const handleUpdateKeyValue = (root_key, key, value) => {
        updateKeyValue(key, value);
    }

    return (
        <div className="container">
            <Form>
                {
                    prepareWidget(modal_input_Config[modal_key], handleUpdateKeyValue, data, processFileUpload, generateSuggestions,
                        suggestion, errors)
                }
            </Form>
        </div>
    );
}

export default CommonModal;
