export const SET_INTERVIEWW = 'SET_INTERVIEWW';

export const saveInterview = (data) => {
    return {
        type: SET_INTERVIEWW,
        payload: {
            request: {
                url: 'api/v1/interview',
                method: 'post',
                data: data,
            },
        },
    };
};
