import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { prepareErrorMessage, prepareWidget } from '../../util';
import { job_conf } from '../../config/jobconfig';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';



function CallInterView(props) {
    const [interview, setInterview] = useState({});
    const [error, setError] = useState({});
    const { handleSaveInterview, user_id, job_id, onHide } = props;
    const handleUpdateKeyValue = (root_key, key, value) => {
        setInterview({ ...interview, [key]: value });
    }
    const processFileUpload = async (array, dispatch_trigger = true) => {


    }

    const handleSave = () => {
        let custom_error = prepareErrorMessage(job_conf['iterview_schedule'], interview);
        setError(custom_error);
        handleSaveInterview(
            {
                schedule: interview['interview_schedule'],
                message: interview['interview_message'],
                user_id,
                job_id
            });
        onHide(false);
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Call for inter view
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        prepareWidget(job_conf['iterview_schedule'], handleUpdateKeyValue, interview, processFileUpload, error)
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-danger' onClick={() => {
                    handleSave();
                }}>Save</Button>
                <Button onClick={() => {
                    onHide(false);
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CallInterView;
