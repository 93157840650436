
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function FilterArea({ filterObject, sort, setSort, searchFilter, job_id, setFilterApplied }) {

    const instituteOption = filterObject[3] ? filterObject[3] : [];
    const expericeOption = filterObject[2] ? filterObject[2] : [];
    const genderOption = filterObject[1] ? filterObject[1] : [];

    const [filter, setFilter] = useState(
        {
            filter1: '',
            filter2: '',
            filter3: '',
            filter4: '',
        });


    const [type, setType] = useState(
        {
            type1: '',
            type2: '',
            type3: '',
            type4: '',
        });



    const getFilterString = () => {
        let filterArray = [];
        if (filter['filter1'] !== '') {
            filterArray.push(filter['filter1']);
        }
        if (filter['filter2'] !== '') {
            filterArray.push(filter['filter2']);
        }
        if (filter['filter3'] !== '') {
            filterArray.push(filter['filter3']);
        }
        if (filter['filter4'] !== '') {
            filterArray.push(filter['filter4']);
        }
        return filterArray.join(",");
    }



    const getTypeString = () => {
        let filterArray = [];
        if (type['type1'] !== '') {
            filterArray.push(type['type1']);
        }
        if (type['type2'] !== '') {
            filterArray.push(type['type2']);
        }
        if (type['type3'] !== '') {
            filterArray.push(type['type3']);
        }
        if (type['type4'] !== '') {
            filterArray.push(type['type4']);
        }
        return filterArray.join(",");
    }

    useEffect(() => {
        (async () => {

            try {

                if (getTypeString() !== '' && getFilterString() !== '') {
                    setFilterApplied(false);
                    await searchFilter(getTypeString(), getFilterString(), job_id);
                    setFilterApplied(true);
                } else {
                    setFilterApplied(false);
                }

            } catch (e) { }


        })();
    }, [filter, type]);

    return (
        <div className="filter_area row">
            <div className="sort_panel col-sm-3">
                <p>Sort by:</p>
                <a href="#" className="c_btn time_sort"
                    onClick={(e) => {
                        e.preventDefault();
                        setSort(sort === 0 ? 1 : 0);
                    }}
                >Time</a>
                <a onClick={(e) => {
                    e.preventDefault();
                    setSort(sort === 2 ? 3 : 2);
                }} href="#" className="c_btn a_to_z">
                    {
                        sort === 2 ? `Z to A` : `A to Z`
                    }</a>
            </div>

            <div className="sort_panel col-sm-9">
                <p>Filter by:</p>
                <select onChange={async (e) => {
                    setFilter({ ...filter, filter1: e.target.value });
                    setType({ ...type, type1: e.target.value !== '' ? 3 : '' });
                }} className="filter_drop_down">
                    <option value={''}>List of Institute</option>
                    {
                        instituteOption.map(option => {
                            return <option key={option['id']} value={option['title']}>
                                {
                                    option['title']
                                }
                            </option>
                        })
                    }
                </select>
                <select onChange={async (e) => {
                    setFilter({ ...filter, filter2: e.target.value });
                    setType({ ...type, type2: e.target.value !== '' ? 2 : '' });
                }} className="filter_drop_down">
                    <option value={``}>Experience</option>
                    {
                        expericeOption.map(option => {
                            return <option key={option['id']} value={option['title']}>
                                {
                                    option['title']
                                }
                            </option>
                        })
                    }
                </select>
                <select onChange={async (e) => {
                    setFilter({ ...filter, filter4: e.target.value });
                    setType({ ...type, type4: e.target.value !== '' ? 4 : '' });
                }} className="filter_drop_down">
                    <option value={``}>Exp. Salary</option>
                    <option value="10000-20000">10-20K</option>
                    <option value="20000-30000">20-30K</option>
                    <option value="30000-40000">30-40K</option>
                    <option value="40000-50000">40-50K</option>
                    <option value="50000-60000">50-60K</option>
                    <option value="60000-70000">60-70K</option>
                </select>
                <select onChange={async (e) => {
                    setFilter({ ...filter, filter3: e.target.value });
                    setType({ ...type, type3: e.target.value !== '' ? 1 : '' });
                }} className="filter_drop_down">
                    <option value={``}>Gender</option>
                    {
                        genderOption.map(gender => {
                            return <option key={gender['id']} value={gender['title']}>
                                {
                                    gender['title']
                                }
                            </option>
                        })
                    }
                </select>
            </div>
        </div >
    );
}

export default FilterArea;
