export const FETCH_SUGGESTION = `FETCH_SUGGESTION`;
export const RESET_SUGGESTION = `RESET_SUGGESTION`;

export const fetchSuggestion = (title, index, status) => {

    return {
        type: FETCH_SUGGESTION,
        payload: {
            request: {
                url: `api/v1/search/index?title=${encodeURI(title)}&status=${status}&index=${index}`,
                method: 'get'
            },
        },
    };
}


export const resetSuggestion = (status) => {

    return {
        type: RESET_SUGGESTION,
        payload: {
            status
        },
    };
}