import { FETCH_SINGLE_JOB, SHORT_LIST_CANDIDATE } from '../../actions/jobAction';

const initialState = {

};

export function jobObjectReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {



        case `${FETCH_SINGLE_JOB}_SUCCESS`: {


            return {
                ...state, [payload['data']['data']['id']]: payload['data']['data']
            };

        }

        case `${SHORT_LIST_CANDIDATE}_SUCCESS`:
            const job_id = payload['data']['data']['job_id'];
            const candidate_id = payload['data']['data']['user_id'];
            const status = payload['data']['data']['status'];
            return {
                ...state, [job_id]: {
                    ...state[job_id], candidates: state[job_id]['candidates'].map((candidate) => {
                        return candidate['id'] === candidate_id ? {
                            ...candidate, pivot: { ...candidate['pivot'], status: status }
                        } : candidate
                    })
                }
            };





        default:
            return state;
    }
}
