import Form from 'react-bootstrap/Form';
import { memo, useRef, useState } from "react";
import { isValidUrl } from '../../util';

const FileInputCustom = (
    {
        label,
        handleUpdateKeyValue,
        value,
        root_key,
        sub_key,
        invalid_message = '',
        invalid = false,
        processFileUpload,
        key_index,
        type = `text`
    }
) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const inputElement = useRef();
    const [loading, setLoading] = useState(false);
    const handleFileSelect = async (event) => {
        setSelectedFile(event.target.files[0])

        if (event.target.files[0]) {
            setLoading(true);
            await processFileUpload({ root_key, sub_key, key_index, file: event.target.files[0] });
            setLoading(false);
        }

    }

    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <input className={invalid ? 'form-control is-invalid' : 'form-control'} onChange={handleFileSelect} ref={inputElement} type="file" />
            {
                invalid && <div className="invalid-feedback">
                    {invalid_message}
                </div>
            }
            {
                loading && <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
            {
                isValidUrl(value) && <a target={`_blank`} href={`${value}`}>Open Link</a>
            }
        </div>
    );
}

export default memo(FileInputCustom);



