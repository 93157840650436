import { FETCH_CURRENT_JOBS } from '../../actions/jobAction';
import { LOGOUT } from '../../actions';
const initialState = {

};

export function jobsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {



        case `${FETCH_CURRENT_JOBS}_SUCCESS`: {


            return {
                ...state, data: payload['data']['data']
            }
        };





        // case LOGOUT:
        //     return initialState;


        default:
            return state;
    }
}
