import { UPDATE_JOB_KEY_VALUE, EDIT_JOB_DETAIL, RESET_JOB } from '../../actions/jobAction';
import { LOGOUT } from '../../actions';
const initialState = {

};

export function jobReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {



        case UPDATE_JOB_KEY_VALUE: {


            return {
                ...state, [payload['root_key']]: { ...state[payload['root_key']], [payload['key']]: payload['value'] }
            };

        }

        case EDIT_JOB_DETAIL: {


            return {
                ...state, job: { ...state['job'], ...payload['job'] }
            };

        }


        case RESET_JOB:
            return initialState;

        case LOGOUT:
            return initialState;


        default:
            return state;
    }
}
