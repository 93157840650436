
import { useEffect, useState } from "react";



function CandidateFilter({ filterObject, sort,   setInstitute,
    setSalary,
    setGender,
    setExperience }) {

    const instituteOption = filterObject[3] ? filterObject[3] : [];
    const expericeOption = filterObject[2] ? filterObject[2] : [];
    const genderOption = filterObject[1] ? filterObject[1] : [];

   

   

    return (
        <div className="filter_area row">
            <div className="sort_panel col-sm-3">
                <p>Sort by:</p>
                <a href="#" className="c_btn time_sort"
                    onClick={(e) => {
                        e.preventDefault();
                      
                    }}
                >Time</a>
                <a onClick={(e) => {
                    e.preventDefault();
                  
                }} href="#" className="c_btn a_to_z">
                    {
                        sort === 2 ? `Z to A` : `A to Z`
                    }</a>
            </div>

            <div className="sort_panel col-sm-9">
                <p>Filter by:</p>
                <select onChange={async (e) => {
                    setInstitute(e.target.value);
                }} className="filter_drop_down">
                    <option value={''}>List of Institute</option>
                    {
                        instituteOption.map(option => {
                            return <option key={option['id']} value={option['title']}>
                                {
                                    option['title']
                                }
                            </option>
                        })
                    }
                </select>
                <select onChange={async (e) => {
                   setExperience(e.target.value);
                }} className="filter_drop_down">
                    <option value={``}>Experience</option>
                    {
                        expericeOption.map(option => {
                            return <option key={option['id']} value={option['title']}>
                                {
                                    option['title']
                                }
                            </option>
                        })
                    }
                </select>
                <select onChange={async (e) => {
                  setSalary(e.target.value);
                }} className="filter_drop_down">
                    <option value={``}>Exp. Salary</option>
                    <option value="10000-20000">10-20K</option>
                    <option value="20000-30000">20-30K</option>
                    <option value="30000-40000">30-40K</option>
                    <option value="40000-50000">40-50K</option>
                    <option value="50000-60000">50-60K</option>
                    <option value="60000-70000">60-70K</option>
                </select>
                <select onChange={async (e) => {
                     setGender(e.target.value);
                }} className="filter_drop_down">
                    <option value={``}>Gender</option>
                    {
                        genderOption.map(gender => {
                            return <option key={gender['id']} value={gender['title']}>
                                {
                                    gender['title']
                                }
                            </option>
                        })
                    }
                </select>
            </div>
        </div >
    );
}

export default CandidateFilter;
