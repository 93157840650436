import Form from 'react-bootstrap/Form';
import { memo } from "react";

const TextInputCustom = ({ label, handleUpdateKeyValue, value, root_key, sub_key, invalid_message = '', invalid = false, type = `text` }) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control className={invalid && 'is-invalid'} onChange={(e) => {

                handleUpdateKeyValue(root_key, sub_key, e.target.value);
            }} value={value} type={type} placeholder={label} />
            {
                invalid && <div className="invalid-feedback">
                    {invalid_message}
                </div>
            }



        </Form.Group>
    );
}

export default memo(TextInputCustom);



