export const UPDATE_JOB_KEY_VALUE = 'UPDATE_JOB_KEY_VALUE';
export const STORE_JOB_DETAIL = 'STORE_JOB_DETAIL';
export const EDIT_JOB_DETAIL = 'EDIT_JOB_DETAIL';
export const FETCH_CURRENT_JOBS = 'FETCH_CURRENT_JOBS';
export const RESET_JOB = 'RESET_JOB';
export const APPLY_JOB = 'APPLY_JOB';
export const FETCH_SINGLE_JOB = 'FETCH_SINGLE_JOB';
export const SHORT_LIST_CANDIDATE = 'SHORT_LIST_CANDIDATE';
export const FETCH_FILTER_INDEX = 'FETCH_FILTER_INDEX';
export const FETCH_FILTER_PROFILE_INDEX = 'FETCH_FILTER_PROFILE_INDEX';

export const updateJobRootKeyValue = (root_key, key, value) => {
    return {
        type: UPDATE_JOB_KEY_VALUE,
        payload: {
            root_key, key, value
        },
    };
}



export const storeJob = (data) => {
    // console.log(data);
    return {
        type: STORE_JOB_DETAIL,
        payload: {
            request: {
                url: `api/v1/job`,
                method: 'post',
                data: data
            }
        }
    };
};


export const fetchCurretJobs = () => {
    return {
        type: FETCH_CURRENT_JOBS,
        payload: {
            request: {
                url: `api/v1/jobs`,
                method: 'get',
            },
        },
    };
};

export const editJobDetail = (job, id) => {
    return {
        type: EDIT_JOB_DETAIL,
        payload: {
            job: { ...job, id: id }
        },
    };
}



export const resetJob = () => {
    return {
        type: RESET_JOB,
        payload: {

        },
    };
}


export const fetchJob = (job_id) => {
    return {
        type: FETCH_SINGLE_JOB,
        payload: {
            request: {
                url: `api/v1/job/fetch/${job_id}`,
                method: 'get',
            },
        },
    };
};


export const applyJob = (data) => {
    return {
        type: APPLY_JOB,
        payload: {
            request: {
                url: `api/v1/job/apply`,
                method: 'post',
                data: data
            }
        }
    };
};


export const makeShortList = (job_id, candidate_id, status) => {

    return {
        type: SHORT_LIST_CANDIDATE,
        payload: {
            request: {
                url: `api/v1/job/shortlist`,
                method: 'post',
                data: {
                    job_id, candidate_id, status
                }
            }
        }
    };

}


export const fetchIndexes = (type) => {

    return {
        type: FETCH_FILTER_INDEX,
        payload: {
            request: {
                url: `api/v1/job/indexes?type=${type}`,
                method: 'get',
            },
        },
    };

}


export const fetchIndexesProfile = (type, title, job_id) => {

    return {
        type: FETCH_FILTER_PROFILE_INDEX,
        payload: {
            request: {
                url: `api/v1/job/indexes/profile?type=${type}&title=${encodeURI(title)}&job_id=${job_id}`,
                method: 'get',
            },
        },
    };

}