export const UPDATE_KEY_VALUE = 'UPDATE_KEY_VALUE';
export const UPDATE_KEY_VALUE_ARRAY = 'UPDATE_KEY_VALUE_ARRAY';
export const STORE_JOB_PROFILE = 'STORE_JOB_PROFILE';
export const FETCH_JOB_PROFILE = 'FETCH_JOB_PROFILE';
export const EDIT_KEY_VALUE_ARRAY = 'EDIT_KEY_VALUE_ARRAY';
export const UPLOAD_JOB_FILES = 'UPLOAD_JOB_FILES';
export const DISPATCH_UPLOAD = 'DISPATCH_UPLOAD';
export const RESET_PROFILE = 'RESET_PROFILE';

export const resetProfile = () => {
    return {
        type: RESET_PROFILE,
        payload: {

        }
    };
}

export const updateRootKeyValue = (root_key, key, value) => {

    return {
        type: UPDATE_KEY_VALUE,
        payload: {
            root_key, key, value
        },
    };
}


export const updateRootKeyValueArray = (root_key, single_array) => {
    return {
        type: UPDATE_KEY_VALUE_ARRAY,
        payload: {
            root_key, single_array
        },
    };
}


export const storeProfile = (data) => {
    return {
        type: STORE_JOB_PROFILE,
        payload: {
            request: {
                url: `api/v1/profile`,
                method: 'post',
                data: data
            }
        }
    };
};


export const fetchJobProfile = () => {
    return {
        type: FETCH_JOB_PROFILE,
        payload: {
            request: {
                url: `api/v1/profile`,
                method: 'get',
            },
        },
    };
};


export const editRootKeyValueArray = (root_key, single_array) => {
    return {
        type: EDIT_KEY_VALUE_ARRAY,
        payload: {
            root_key, single_array
        },
    };
}

export const uploadJobFiles = (data) => {
    return {
        type: UPLOAD_JOB_FILES,
        payload: {
            request: {
                url: `api/v1/profile/upload/files`,
                method: 'post',
                data: data
            }
        }
    };
};

export const uploadJobFilesCustom = (data) => {
    return {
        type: UPLOAD_JOB_FILES,
        payload: {
            request: {
                url: `api/v1/profile/upload/files/custom`,
                method: 'post',
                data: data
            }
        }
    };
};



export const dispatchUpload = (root_key, sub_key, url) => {
    return {
        type: DISPATCH_UPLOAD,
        payload: {
            root_key, sub_key, url
        },
    };
};
