import Form from 'react-bootstrap/Form';
import { memo } from "react";
const SelectInputCustom = ({ label, handleUpdateKeyValue, value, root_key, sub_key, data, invalid_message = '', invalid = false }) => {
    return (
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{label}</Form.Label>
            <Form.Select className={invalid && 'is-invalid'} onChange={(e) => {
                handleUpdateKeyValue(root_key, sub_key, e.target.value);
            }} value={value} aria-label="Default select example">
                <option value={``}>{label}</option>
                {
                    data.map((d, key) => {
                        return (
                            <option key={key} value={d['value']}>{d['label']}</option>
                        )
                    })
                }
            </Form.Select>
            {
                invalid && <div className="invalid-feedback">
                    {invalid_message}
                </div>
            }
        </Form.Group>
    );
}

export default memo(SelectInputCustom);