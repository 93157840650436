
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRootKeyValue, updateRootKeyValueArray, storeProfile, editRootKeyValueArray } from '../actions/profileAction';
import { NextPrev } from '../components/ActionButton/ButtonAll';
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from '../components/Modal/CommonModal';
import { modal_input_Config } from '../config/config';
import { prepareErrorMessage } from '../util';

function CustomCentralModal(props) {
    const {
        updateEducation,
        editEducationData = {},
        modal_key,
        processFileUpload,
        generateSuggestions,
        suggestion } = props;
    const [education, setEducation] = useState({});
    const [errors, setErrors] = useState({});
    const handleUpdateKeyValue = (key, value) => {
        setEducation({ ...education, [key]: value });
    }
    const processUpload = async (array) => {
        const response = await processFileUpload(array, false);
        setEducation({ ...education, [response['sub_key']]: response['url'] });
    }
    useEffect(() => {
        setEducation(editEducationData);
    }, [editEducationData]);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Eduation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CommonModal
                    processFileUpload={processUpload}
                    errors={errors}
                    modal_key={modal_key}
                    data={education}
                    updateKeyValue={handleUpdateKeyValue}
                    generateSuggestions={generateSuggestions}
                    suggestion={suggestion}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-danger' onClick={() => {
                    let custom_error = prepareErrorMessage(modal_input_Config[modal_key], education);
                    setErrors(custom_error);
                    if (Object.keys(custom_error).length > 0)
                        return;
                    updateEducation(education);
                    props.onHide();
                }}>Save</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ArrayWrapper({ updateEducation, educations, modal_key, title, processFileUpload, generateSuggestions, suggestion }) {
    const { slug, sub_token } = useParams();
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [editEducationData, setEditEducationData] = useState({});

    return (
        <>
            <div className='row'>
                <div className='col-sm-8'>
                    <h2>{title}</h2>
                </div>
                <div className='col-sm-4'>
                    <button onClick={() => {
                        setEditEducationData({});
                        setModalShow(true);
                    }} style={{ float: 'right' }} className="btn  btn-danger" type="button">
                        Add
                    </button>
                    <CustomCentralModal
                        editEducationData={editEducationData}
                        updateEducation={updateEducation}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        modal_key={modal_key}
                        processFileUpload={processFileUpload}
                        generateSuggestions={generateSuggestions}
                        suggestion={suggestion}

                    />
                </div>
            </div>
            {
                educations.length === 0 && <h3>No data available</h3>
            }
            {
                educations.map(education => {
                    return (
                        <div key={education['id']} className="row" style={{ backgroundColor: '#F8F9FA', padding: '20px', marginTop: '10px' }}>
                            {
                                modal_key === 'education_config' && <>
                                    <div className="col-sm-8">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {education['institute']}
                                        </p>
                                        <p>
                                            {education['education_level']}
                                        </p>
                                    </div>
                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Result
                                        </p>
                                        <p>
                                            Grade:  {education['result']}
                                        </p>
                                    </div>
                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Passing year
                                        </p>
                                        <p>
                                            {education['year']}
                                        </p>
                                        <button type='button' onClick={() => {
                                            setEditEducationData(education);
                                            setModalShow(true);

                                        }} className='btn btn-success'>Edit</button>
                                    </div>
                                </>
                            }


                            {
                                modal_key === 'experience_config' &&
                                <>
                                    <div className="col-sm-8">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {education['organisation_name']}
                                        </p>

                                        <p>
                                            {education['designation']}
                                        </p>

                                    </div>
                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Location
                                        </p>
                                        <p>
                                            {education['location']}
                                        </p>


                                    </div>

                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Passing year
                                        </p>
                                        <p>
                                            {education['year']}
                                        </p>
                                        <button onClick={() => {
                                            setEditEducationData(education);
                                            setModalShow(true);

                                        }} type='button' className='btn btn-success'>Edit</button>
                                    </div>

                                </>
                            }


                            {
                                modal_key === 'references_config' &&
                                <>
                                    <div className="col-sm-8">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {education['name']}
                                        </p>

                                        <p>
                                            {education['occupation']}
                                        </p>

                                    </div>
                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Designation
                                        </p>
                                        <p>
                                            {education['designation']}
                                        </p>


                                    </div>

                                    <div className="col-sm-2">
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            Organization
                                        </p>
                                        <p>
                                            {education['organization']}
                                        </p>
                                        <button onClick={() => {
                                            setEditEducationData(education);
                                            setModalShow(true);

                                        }} type='button' className='btn btn-success'>Edit</button>
                                    </div>
                                </>
                            }

                        </div>
                    )

                })
            }
        </>
    );
}

export default ArrayWrapper;
