import { makeShortList, fetchIndexes, fetchIndexesProfile, fetchCurretJobs } from "../actions/jobAction";
import { fetchAllProfile } from "../actions/";
import { saveInterview } from "../actions/interviewAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import ProfileWrapper from "../components/Profile/ProfileWrapper";
import { putToken } from '../actions/';
import { isInArrayFunction } from "../Utils/util";
import ProfileList from "../components/Profile/ProfileList";
import SearchFilter from "../components/FilterPanel/SearchFilter";
import CandidateFilter from "../components/FilterPanel/CandidateFilter";


function AllProfileScreen() {
    // const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const [profile_index, setProfileIndex] = useState(0);
    const { sub_token } = useParams();
    const users = useSelector(state => state.users && state.users);
    const filterObject = useSelector(state => state.filterObject && state.filterObject);

    const candidates = users['data'] ? users['data'] : [];
    const pagination = users['pagination'] ? users['pagination'] : null;
    const [sort, setSort] = useState(0);
    const [filter_applied, setFilterApplied] = useState(false);
    const arrayFilter = filterObject && filterObject['candidate_filter'] ? filterObject['candidate_filter'] : []

    const jobObject = useSelector(state => state.jobs && state.jobs);
    const jobs = jobObject['data'] ? jobObject['data'] : [];

    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [job_id, setJobId] = useState('');


    const [salary, setSalary] = useState('');
    const [institute, setInstitute] = useState('');
    const [gender, setGender] = useState('');
    const [experience, setExperience] = useState('');

    const listInnerRef = useRef();

    // const selected_candidate = candidates.filter(candidate => {
    //     return candidate['pivot']['status'] === 1
    // });
    const retriveAndSetProfileIndex = (id) => {
        let index = candidates.findIndex(can => {
            return can['id'] === id;
        });
        setProfileIndex(index);

    }
    const makeApplyShortList = async (job_id, candidate_id, status) => {
        await dispatch(makeShortList(job_id, candidate_id, status));
    }

    const filterFunction = (x) => {

        if (filter_applied) {
            return isInArrayFunction(x['id'], arrayFilter);
        }
        else
            return true;
    }

    const sortFunction = (x, y) => {

        if (sort === 0)
            return x['id'] - y['id'];
        else if (sort === 1) return y['id'] - x['id'];
        else if (sort === 3) return x.name.localeCompare(y.name);
        else return y.name.localeCompare(x.name);
    }


    const searchFilter = async (type, title, job_id) => {
        await dispatch(fetchIndexesProfile(type, title, job_id));
    }



    const handleSaveInterview = async (data) => {
        await dispatch(saveInterview(data));
    }


    // const profileSearch = useCallback(() => {
    //     dispatch(fetchAllProfile(page, title));
    //   }, [page, title]);


    const profileSearch = async () => {
        setLoading(true);
        try {
            // job_id = '', institute = '', salary = '', gender = ''
            await dispatch(fetchAllProfile(page, title, job_id, institute, salary, gender, experience));
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }


    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (!loading && pagination) {
                    if (pagination['last_page'] > page)
                        setPage(page + 1);
                }
                // console.log('AAA');
            }
        }
    };

    useEffect(() => {
        (async () => {

            try {

                profileSearch();
            } catch (e) { }


        })();
    }, [title, page, job_id, salary, institute, gender, experience]);

    useEffect(() => {
        (async () => {

            try {
                await dispatch(putToken(sub_token));
                await dispatch(fetchCurretJobs());
                await dispatch(fetchIndexes(1));
                await dispatch(fetchIndexes(2));
                await dispatch(fetchIndexes(3));

            } catch (e) { }


        })();
    }, []);

    return (
        <div className="container">


            <>
                <div className="job_heading row">

                    <select onChange={async (e) => {
                        // console.log(e.target.value);
                        setJobId(e.target.value);
                    }} className="filter_drop_down">
                        <option value={``}>Select Job</option>
                        {
                            jobs.map(job => {
                                return <option key={job['id']} value={job['id']}>{job['title']}</option>
                            })
                        }
                    </select>
                </div>
                <CandidateFilter
                    setFilterApplied={setFilterApplied}
                    searchFilter={searchFilter}
                    sort={sort}
                    setSort={setSort}
                    filterObject={filterObject}
                    setInstitute={setInstitute}
                    setSalary={setSalary}
                    setGender={setGender}
                    setExperience={setExperience}
                />
                <div className="row condidate_container">
                    <div className="col-sm-3">
                        <SearchFilter setPage={setPage} setTitle={setTitle} title={title} profileSearch={profileSearch} />
                        <div className="condidate_list"
                            onScroll={onScroll}
                            ref={listInnerRef}>
                            <ProfileList
                                job={jobObject}
                                makeApplyShortList={makeApplyShortList}
                                retriveAndSetProfileIndex={retriveAndSetProfileIndex}
                                // status={index}
                                candidates={candidates.sort(sortFunction).filter(filterFunction)}
                            />
                        </div>
                    </div>
                    <div className="col-sm-9 profile_area">
                        {
                            candidates[profile_index] && <ProfileWrapper profile={candidates[profile_index]} />
                        }
                    </div>
                </div>


            </>


        </div >
    );
}

export default AllProfileScreen;
